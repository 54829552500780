import React from 'react';
import moment from 'moment';
import styles from './Meta.module.scss';
import 'moment/locale/fr';

const Meta = ({ date }) => (
  <div className={styles['meta']}>
    <p className={styles['meta__date']} style={date ? {} : { display: 'none' }}>Published {moment(date).format('D MMM YYYY')}</p>
  </div>
);

export default Meta;
